// PRODUCTS DETAIL
/*

*/

.product-detail {

	h1 {
		text-transform: uppercase;

		small {
			display: block;
			color: #888888;

            em {
                display: inline-block;
                margin-right: 7px;
                text-transform: none;
            }
		}
	}

	.wrap-product-infos {
		display: flex;
		flex-wrap: wrap;
	}

	.back {
		flex:0 0 100%;
		padding-top:30px;
		order:2;
	}

	.product-infos {
		flex:0 0 100%;

		.wrap-product-infos-img {
			.main-img {
				background-color: white;
				border:1px solid @gray;
				padding:20px;
				max-width:500px;
				margin:0 auto 30px;
				text-align: center;
			}

            // Thumbs (Easynut v2, v3)
            .product-thumbs {
                display: flex;
                max-width: 500px;
                margin:0 auto 30px;

                li {
                    flex:0 0 100px;
                    margin-left: 20px;
                    border:1px solid @gray;

                    &:nth-child(1) {
                        margin-left: 0;
                    }

                    a, picture, img {
                        display: block;
                    }
                }
            }
		}

		.wrap-product-infos-texts {
			h2 {
				text-transform: uppercase;
			}

			.ask-quotation {
				border-top:1px solid @gray;
				border-bottom:1px solid @gray;
				margin-bottom:30px;
			}
		}

		// Youtube video
		.wrap-video {
			.video-container {
				position: relative;
				padding-bottom: 56.25%;
				padding-top: 30px;
				height: 0;
				overflow: hidden;
			}

			.video-container iframe,
			.video-container object,
			.video-container embed {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}

		}
	}



	// Screen > 767px
	._media-s-up({

	});


	// Screen > 989px
	._media-m-up({

		.back {
			padding:0 0 30px 0;
			order:-2;
		}

		.product-infos {
			display: flex;

			.wrap-product-infos-img {
				flex:0 0 300px
			}

			.wrap-product-infos-texts {
				flex:1;
				padding-left:40px;


				.ask-quotation {
					border-top:1px solid @gray;
					border-bottom:1px solid @gray;
					margin-bottom:30px;
				}
			}
		}
	});

	// Screen > 1199px
	._media-l-up({
		.product-infos {
			.wrap-product-infos-img {
				flex:0 0 500px
			}
		}
	});
}

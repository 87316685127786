// HEADER / FOOTER
#main-header {
	.cc-inside {
		padding: 0;
		position: relative;
	}

	// LOGO
	#logo {
		display: block;
		padding:20px;

		picture {
			padding-right:74px;
		}
	}

	// 3 pushes : phone, fabrication, entreprise familiale;
	.wrap-pushes {
		background-color: @primary;
		color:white;
		display: flex;
		padding:20px 0;

		& > div {
			flex: 0 0 33.2%;
			max-width:33.2%;
			text-align: center;
			font-size: 1.5rem;

			i {
				font-size: 4rem;
				height: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 10px;

				&.fa-phone-volume {
					._rotate(-45deg)
				}

				&:before {
					margin: auto;
				}
			}

			img {
				display: block;
				margin:0 auto 10px;
				max-height:50px;
				width: inherit;
				max-width: inherit;
			}

			p {
				a {
					display: block;
					font-weight: 600;
				}
			}
		}

		a {
			color:white;
		}
	}
	
	// Open phoen menu button
	.css-open-menu {
		position: absolute;
		right:20px;
		top:20px;
		z-index: 2;
	}

	// Main-nav
	nav {
		position: fixed;
		height: 100vh;
		z-index: 10;
		background-color: white;
		top: 0;
		left:-120%;
		width: 100%;
		bottom: 0;
		padding-top:30px;
		overflow-y: auto;
		
		&.shown {
			._transition(0.35s, left, cubic-bezier(1, 0, 0, 1));
			left:0%;
		}

		& > ul {
			max-width:70%;
			margin: 0 auto;

			& > li {
				text-align: center;

				&:hover,
				&.active {
					&:after {
						width:100%;
					}
				}

				// Demande de devis
				&.ask-quotation {
					background-color: @orange;
					._transition(0.2s, backgorund-color);

					&:before,
					&:after {
						content: '';
						display: none;
					}

					a {
						color: white;
					}

					&:hover {
						background-color: darken(@orange, 5%);
					}
				}

				&:after {
					height:8px;
					width:0%;
					content: ' ';
					display: block;
					margin:0 auto;
					background-color:@orange;
					._transition;
				}

				a {
					display: block;
					padding: 10px 20px;
					position: relative;
					text-transform: uppercase;

					// Logo with "Accueil" link
					picture {
						text-align: center;
						margin:0 auto 20px;
						max-width: 80%;
					}
				}

				li {
					a {
						padding:3px 20px;
						text-transform: none;

						&:hover {
							color:@orange;
						}
					}
				}
			}
		}
	}



	// Screen > 767px
	._media-s-up({
		// 3 pushes : phone, fabrication, entreprise familiale;
		.wrap-pushes {

			& > div {
				padding:0 7px;
				font-size: 1.8rem;

				i {
					font-size: 5rem;
					height: 73px;
				}

				img {
					max-height:inherit;
					width: auto;
					max-width: 100%;
				}

				p {
					span {
						display: block;
						font-weight: 600;
						font-size: 110%;
					}
				}
			}
		}
	});


	// Screen > 989px
	._media-m-up({
		position: relative;

		&:after {
			display: block;
			content: ' ';
			background-color:@primary;
			width: 50%;
			position: absolute;
		    right: 0;
		    top: 0;
		    bottom: 0;
		    width: 50%;
		    z-index: -1;
		}

		// Hamburger
		.css-open-menu {
			display: none;
		}

		.wrap-logo-pushes {
			display: flex;
			align-items: center;

			#logo {
				flex:0 1 360px;
				max-width: 360px;
				padding:0 30px 0 0;
			}

			.wrap-pushes {
				flex:1;
				padding:20px 0 50px;
				position: relative;

				&:before {
					display: block;
					content: ' ';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 0 210px 70px;
					border-color: transparent transparent @primary transparent;
					position: absolute;
					top:0;
					left:-70px;

				}
			}
		}

		// Main-nav
		nav {
			position: absolute;
			top:inherit;
			bottom:-35px;
			left:0;
			height: inherit;
			padding: 0;
			overflow-y: inherit;
			box-shadow: 0 0 10px 0px rgba(0,0,0,0.2);
			transition: none;

			&.shown {
				transition: none;
			}

			// Sticky Navigation
			&.fixed {
				position: fixed;
				top:0;
				left:0;
				right:0;
				bottom: inherit;
				width:100vw;
				z-index: 11;

				& > ul {
					._inside(900px);
					transition: none;
				}
			}


			& > ul {
				display: flex;
				flex-wrap: wrap;
				max-width: 100%;

				& > li {
					flex: 1;
					position: relative;

					&:hover,
					&.active {
						&:after {
							width: 100%;
							transition: none;
						}
					}

					&:hover {
						ul {
							display: block;
							.eurintecFadeInDown;
						}
					}

					&:before {
						display: none;
						content: '';
					}

					a {
						padding: 20px 0 12px;
						font-size: 1.4rem;

						// Logo with "Accueil" link
						picture {
							display: none;
						}
					}

					ul {
						display: none;
						position: absolute;
						top:50px;
						width:200px;
						background-color: white;
						border-top: 8px solid @orange;
						box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.2);
						padding:15px 0;

						a {
							text-align: left;
							font-size: 1.6rem;
						}
					}
				}
			}
		}
	});

	// Screen > 1999px;
	._media-l-up({

		.wrap-logo-pushes {
			#logo {
				flex:0 1 490px;
				max-width: 490px;
			}

			// 3 pushes : phone, fabrication, entreprise familiale;
			.wrap-pushes {	
				& > div {
					p {
						span,a {
							font-size: 2.3rem;
						}
					}
				}
			}
		}

		// Main-nav
		nav {
			// Sticky Navigation
			&.fixed {
				& > ul {
					._inside(1200px); 
				}
			}

			
			& > ul {
				& > li {

					&:hover,
					&.active {
						ul {
							top:55px;
						}
					}

					a {
						font-size: 1.8rem;
					}
				}
			}
		}
	});
}


#main-footer {
	.footer-top {
		color: @text-mc;

		li.active a {
			color: @orange;
		}

		a {
			color: @text-mc;
			._transition;

			&:hover {
				color: @orange;
			}
		}

		.cc-inside > div {
			padding:30px 0 0;
		}

		address {
			text-align: center;
			font-style: normal;

			picture {
				max-width:200px;
				margin:0 auto 20px;
			}
		}

		h4 {
			font-weight: 700;
			text-align: center;
			margin-bottom:15px;
		}
	}

	// Screen > 767px
	._media-s-up({
		.footer-top {
			.cc-inside {
				display: flex;
				flex-wrap: wrap;

				address,
				.about {
					flex:1 0 100%;
				}

				.products,
				.informations {
					flex: 0 0 50%;

					h4 {
						text-align: left;
					}
				}
			}
		}
	});


	// Screen > 989px
	._media-m-up({
		.footer-top {
			position: relative;

			&:hover {
				.polygon {
					clip-path: polygon(0 0, 35% 0, 45% 100%, 0% 100%);
				}
			}

			.polygon {
				position: absolute;
				z-index:1;
				left:0;
				top:0;
				bottom:0;
				right:0;
				background-color: @gray-da;
				clip-path: polygon(0 0, 45% 0, 55% 100%, 0% 100%);
				._transition;
			}

			

			.cc-inside {
				position: relative;
				z-index:2;
				justify-content: space-between;

				address,
				.about,
				.products,
				.informations {
					flex: 0 0 24%;
					padding: 0 15px;
				}

				address,
				h4 {
					text-align: left;
				}

				.about,
				.products,
				.informations {
					padding-top: 20px;
				}
			}
		}
	});
}
// CONSEILS ET INFOS
/*
    
*/
.conseils {

	.wrap-questions {
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
	}

	// Each question/answer
	article {
		border-bottom: 1px solid #eeeeee;

		&:nth-child(2n+1) {
			h2 a span {
				background-color: #e1e1e1;
			}
		}

		&.active {
			h2 a {
				span {
					background-color:@orange;
				}
				p {
					color: @orange;
				}
			}

			div {
				.showHideAnswer;
			}
		}

		h2 {
			font-size: 2.2rem;

			a {
				display: flex;
				align-items: center;

				&:hover {
					p {
						color:@orange;
					}

					span {
						background-color:@orange;
					}
				}

				span {
					align-self: stretch;
					flex: 0 0 60px;
					min-height:60px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 2.2rem;
					background-color: @gray;
					color:white;
					._transition;
				}

				p {
					flex:1;
					padding-left:20px;
					._transition;
				}
			}
		}

		div {
			border-left: 5px solid @gray-da;
			padding: 0 20px 0 20px;
			max-height: 0px;
			overflow: hidden;
			margin: 0px 0px 0px 30px;
		}
	}
}
// HOMEPAGE
/*
    
*/
.homepage {

	// Big Image + Texts
	section.backstretch {
		padding:40px 0;

		.cc-inside {
			color: white;
		}

		p a {
			color: white;
		}
	}
	h1 {
		color: white;
		text-transform: uppercase;
		font-weight: 700;
		position: relative;

		small {
			display: block;
			color: white;
			text-transform: none;
			font-weight:600;
			font-style: italic;
		}

		&:before {
			display: block;
			content: ' ';
			height:18px;
			width:60%;
			background-color:@orange;
			position:absolute;
			z-index:-1;
			top:0;
			left:0;
		}
	}

	

	/*
	PRODUCTS LISTS
	*/
	.product-list {
		padding:30px 0;

		// Each item
		.product-item {
			padding:20px;
			border:1px solid @gray;
			margin:30px auto 0;
			max-width:360px;
			background-color: white;
			._transition;

			&:nth-child(1) {
				margin-top: 0;
			}

			img {
				max-width:320px;
				max-height:220px;
			}

			// Specific to homepage : no-border and box-shadow !
			&:hover {
				border-color:transparent;
				box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
			}
		}
	}




	// Screen > 767px
	._media-s-up({

		// Big image + texts
		section.backstretch {
			padding-bottom:70px;
		}
		h1 {
			&:before {
				height:23px;
			}
		}

		// Presentation section
		.wrap-presentation {
			.btn {
				display: inline-block;
				width: inherit;
				margin-top:20px;
				margin-left:20px;
				

				&:nth-child(1) {
					margin-left: 0px;
				}
			}
		}

		// Products list 
		.product-list {
			padding:30px 0 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			// Each item
			.product-item {
				flex:0 0 48%;
				max-width:inherit;
				margin:0 0 30px;

				img {
					max-width:100%;
				}

				.btn {
					font-size : 1.5rem;

					i {
						font-size: 1.2rem;
					}
				}
			}
		}
	});


	// Screen > 989px
	._media-m-up({
		section.backstretch {
			min-height: 500px;
			display: flex;
			align-items: center;
		}
	});

	// Screen > 1199px
	._media-l-up({
		section.backstretch {
			min-height: 700px;
		}

		// Products list 
		.product-list {
			// Each item
			.product-item {
				flex:0 0 24%;

				img {
					max-width:320px;
				}

				.btn {
					font-size : 2.2rem;

					i {
						font-size: 1.4rem;
					}
				}
			}
		}
	});
}
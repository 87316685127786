// COMMON CSS FILE
/*
    - Basics styles : strong, Hn
    - .body on CMs pages
    - "Presentation block with 2 pictures on the right" on Homepage and Presantation page
    - Section "Discover our catalog" on Presentation page
*/


/*
	- Basics Styles
*/
// Strong
strong {
  font-weight: 600;
}
// Table in product detail pages
table.caracteristics {
  margin: 0;

  tr {
    &:hover {
      td {
        background-color:@gray;
      }
    }
    td {
      border-bottom-color: white;
      background-color: #f4f4f4;
      ._transition;

      &:last-of-type {
        text-align: right;
      }
    }
  }
}
// Arrowed lists
.arrow {
  li {
    position: relative;
    padding:2px 0 2px 30px;

    &:before {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      content: '\f178';
      color: @orange;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      font-size: 90%;
      position: absolute;
      top:5px;
      left:0;
    }
  }
}
// Buttons, and button links
a.btn,
button {
  font-size: 1.6rem;

  i {
    font-size: 1.4rem;
  }

  i.fa-file-pdf {
    font-size:1.8rem
  }

  ._media-m-up({
    font-size: 2.2rem;

    i.fa-file-pdf {
      font-size: 2rem;
    }
  });

  // Screen > 1199px
  ._media-l-up({
    i.fa-file-pdf {
      font-size: 2.5rem;
    }
  });
      
}
// Section with dots image in background
.bg-illu {
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 350px;

  .webp & {
      background-image:url('/images/bg-illu.webp');
  }
  .no-webp & {
      background-image:url('/images/bg-illu.png');
  }

  ._media-s-up({
    background-size:450px;
  });

  ._media-l-up({
    background-size:560px;
  })
}
// Paddings on sections
.section {
  padding:30px 0 0;
  // Screen > 989px
  ._media-l-up({
    padding-top:60px;
  });
}
// Large image on top of pages
body:not(.homepage):not(.error404) section.backstretch:not(.discover-catalog) {
  min-height:150px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  
  h1,
  p {
    font-weight: 600;
    color: white;
    font-size: 2.5rem;
    padding: 0 20px 20px;
    text-transform: uppercase;
    text-align: center;
  }

  // Screen > 767px
  ._media-s-up({
    min-height:280px;

    h1,
    p {
      font-size : 3rem;
    }
  });

  // Screen > 989px
  ._media-l-up({
    h1,
    p {
      font-size : 3.6rem;
      padding-bottom:40px;
    }
  });
}
// titles
h1, .h1-like {
  font-weight: 600;
  font-size: 2.5rem;

  ._media-s-up({
    font-size : 3rem;
  });

  ._media-l-up({
    font-size: 3.6rem;
  });
}

h2.underlined {
  padding-bottom:10px;
  border-bottom:1px solid @primary;
}


/*
	- .body on CMS pages
*/
.body {
  text-align: justify;

  h2 {
    margin-top: 50px;
  }

  h4,
  h3 {
    margin-top: 20px;
  }

  p {
    padding: 10px 0;
  }

  a:not(.btn) {
    color: @primary;
    display: inline-block;
    border-bottom: 1px dotted @primary;
    padding: 0 3px;
    margin: 0 2px;
    ._transition;

    &:hover {
      color: @orange
    }
  }
}


/*
  - "Presentation block with 2 pictures on the right" on Homepage and Presantation page
*/
// Presentation section
.wrap-presentation {
  small {
    color: @text-mc;
  }

  ul a:hover {
    ._transition;
    color: @orange;
  }

  .btn {
    display: block;
    width: 100%;
    margin-top:20px;
    font-size:1.6rem;
  }

  aside {
    position: relative;
    height:480px;
    max-width: 430px;
    margin:0 auto;

    picture {
      position: absolute;
      top:0;
      right:130px;

      &.item-2 {
        z-index: 2;
        top:180px;
        right:0px;
      }
    }
  }

  // Screen > 767px
  ._media-s-up({
    .btn {
      display: inline-block;
      width: inherit;
      margin-top:20px;
      margin-left:20px;
      
      &:nth-child(1) {
        margin-left: 0px;
      }
    }
  });

  // Screen > 989px
  ._media-m-up({
    display: flex;

    section {
      flex: 1;
      padding-right:30px;
    }

    aside {
      height: inherit;
      max-width: 300px;
      flex:0 0 300px;

      picture {
        top:50px;
        right:110px;
        

        &.item-2 {
          top:210px;
          right:0;
        }

        // Specific to "presentation" page
        .presentation & {
          top:0;

          &.item-2 {
            top:160px;
          }
         
        }
      }
    }       
  });

  // Screen > 1199px
  ._media-l-up({

      .btn {
        font-size: 2.2rem;
      }

      section {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top:110px;

        // Specific to "presentation" page
        .presentation & {
          padding-top:0px;
        }

        & > * {
          flex:0 0 100%;
        }

        & > ul {
          flex: 0 0 48.0%;
        }
      }
    }); 
}


/*
  - "Discover our Catalog" section
*/
.discover-catalog {
  padding:30px 0;

  h2 {
    color:white;
    text-transform: uppercase;
  }

  .arrow {
    text-align: left;

    a {
      color:white;
      ._transition;

      &:hover {
        color:@orange;
      }

      span {
        font-size: 75%;
      }
    }
  }

  .btn {
    font-size: 1.8rem;
    margin:10px 10px 0;
  }

  ._media-s-up({
    .cc-inside {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    h2,
    p {
      flex:0 0 100%
    }

    ul {
      flex: 0 0 48.0%;
    }
  });

  ._media-m-up({

    padding:60px 0;

    .btn {
      margin:10px 30px 0;
      font-size : 2.2rem;
    }

    ul {
      padding:0 10px;
      flex: 0 0 360px;
    }
  });
}


/*
  - Error 404 Page
*/
.error404 {
  .wrap-404 {
    height: 100vh;
    width:100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color:white;

    h1 {
      color: white;
      font-size:6rem;

      small {
        font-size:6rem;
      }
    }
  }
}
// REFERENCES
/*

*/
.references {

    .rea-pictures {

        picture {
            margin-top: 40px;
        }
    }

	// Screen > 767px
	._media-s-up({

	});


	// Screen > 989px
	._media-m-up({

        .rea-pictures {
            display: flex;
            justify-content: space-between;

            picture {
                flex:0 0 48%;
            }
        }

	});
}

// Gill Sans
.gill() { font-family: "Gill Sans", sans-serif; }
.gill { .gill; }


@font-face {
    font-family: 'Gill Sans';
    font-display: optional;
    src: url('/fonts/GillSans-Italic.eot');
    src: url('/fonts/GillSans-Italic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/GillSans-Italic.woff2') format('woff2'),
        url('/fonts/GillSans-Italic.woff') format('woff'),
        url('/fonts/GillSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gill Sans';
    font-display: optional;
    src: url('/fonts/GillSans-SemiBoldItalic.eot');
    src: url('/fonts/GillSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/GillSans-SemiBoldItalic.woff2') format('woff2'),
        url('/fonts/GillSans-SemiBoldItalic.woff') format('woff'),
        url('/fonts/GillSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Gill Sans';
    font-display: optional;
    src: url('/fonts/GillSans-Bold.eot');
    src: url('/fonts/GillSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/GillSans-Bold.woff2') format('woff2'),
        url('/fonts/GillSans-Bold.woff') format('woff'),
        url('/fonts/GillSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gill Sans';
    font-display: optional;
    src: url('/fonts/GillSans-SemiBold.eot');
    src: url('/fonts/GillSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/GillSans-SemiBold.woff2') format('woff2'),
        url('/fonts/GillSans-SemiBold.woff') format('woff'),
        url('/fonts/GillSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gill Sans';
    font-display: optional;
    src: url('/fonts/GillSans.eot');
    src: url('/fonts/GillSans.eot?#iefix') format('embedded-opentype'),
        url('/fonts/GillSans.woff2') format('woff2'),
        url('/fonts/GillSans.woff') format('woff'),
        url('/fonts/GillSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
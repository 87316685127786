// PRODUCTS LIST
/*

*/


.wrap-categories-products {
	aside {
		margin-bottom:30px;
		background-color: @gray;

		.wrap-categories {
            padding:20px;
        }

        .title {
            text-transform: uppercase;
            position: relative;
            text-align: center;

            &:after {
                display: block;
                content: ' ';
                height:1px;
                width:100%;
                background-color:white;
                margin:20px auto;
            }
        }

        a {
        	._transition;

        	&:hover {
        		color:@orange;
        	}
        }
	}

	section section {
		padding:20px;
		border:1px solid @gray;
		margin:30px auto 0;
		background-color: white;
		._transition;

		&:nth-child(1) {
			margin-top: 0;
		}

		img {
			max-width:180px;
			max-height:160px;
		}

		// Hover or selected category
		&.selected,
		&:hover {
			border-color:transparent;
			box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
		}

		.wrap-img-category {
			text-align: center;
			padding-bottom:20px;
		}

		.wrap-desc-category {
			text-align: center;
		}

		.product-list {
			border-top:1px solid @gray;
			padding: 20px 0 0;
			margin-top:20px;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			article {
				padding:20px;
				border:1px solid @gray;
				margin:15px;
				flex: 0 0 258px;
				background-color: white;
				._transition;

				&:nth-child(1) {

				}

				img {
					max-width:208px;
					max-height:150px;
				}

				// Specific to homepage : no-border and box-shadow !
				&:hover {
					border-color:transparent;
					box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
				}

				h3 {
					color: @text-mc;
					font-size: 2.4rem;

                    small {
                        margin-top: 3px;
                        display: block;
                        color: #888888;
                        text-transform: uppercase;

                        em {
                            display: inline-block;
                            margin-right: 7px;
                            text-transform: none;
                        }
                    }
				}
				p {
					color: #7D7D7D;
				}
			}
		}
	}




	// Screen > 767px
	._media-s-up({
		section section {
			display: flex;
			flex-wrap: wrap;

			.wrap-img-category {
				flex:0 0 180px;
				max-width:180px;
				padding-bottom: 0;
			}

			.wrap-desc-category {
				flex: 1;
				padding-left: 40px;
				text-align: left;
			}

			.product-list {
				flex:0 0 100%;
			}
		}

	});


	// Screen > 989px
	._media-m-up({
		.cc-inside {
			display: flex;
		}

        aside {
            flex: 0 0 240px;
            max-width:240px;
            margin-bottom: 0;

            .wrap-categories {
                padding:30px 20px;
            }
        }

        .wrap-products {
            flex: 1;
            padding-left:40px;
        }

        section section {
			.product-list {
				justify-content: flex-start;
			}
		}
	});

	// Screen > 1199px
	._media-l-up({

	});
}

@charset "UTF-8";

// Import normalize
@import 'normalize';

// Import ChuckCSS
@import "../../node_modules/ChuckCSS/chuckcss/front";

// Import Fonts
@import "libraries/fonts";

// Import Fontawesome
@import '../../node_modules/@fortawesome/fontawesome-pro/less/fontawesome';
@import '../../node_modules/@fortawesome/fontawesome-pro/less/light';
@import '../../node_modules/@fortawesome/fontawesome-pro/less/solid';
@import '../../node_modules/@fortawesome/fontawesome-pro/less/brands';

// Override default settings
@import 'settings-override';



html.opened-menu,
body.opened-menu {
  overflow: hidden;

  ._media-m-up({    
    overflow:inherit !important;
  });
}



body {
  .gill;

  // Main containers
  .cc-inside {
    ._inside(100%);
    padding: 0 20px;

    // Screen > 989px
    ._media-m-up({ 
      ._inside(900px); 
      padding: 0;
    });

    // Screen > 1189px
    ._media-l-up({ 
      ._inside(1200px); 
    });
  }

  ul {
    ._reset;

    li {
      list-style: none;
    }
  }

  picture {
    display: block;
  }

  &.references,
  &.presentation {
    #main-content {
      padding-bottom:0;
    }
  }
}

/* define text selection */
::-moz-selection {
  background: @primary;
  color: white;
}

::selection {
  background: @primary;
  color: white;
}


/* Import Helpers files */
@import 'helpers/hamburger';

/* Import libraries plugins files */
@import "libraries/fancybox";
@import "libraries/flickity";
@import "libraries/wow";
@import "libraries/tippyjs";

/* Import Website styles */
@import 'website/common';
@import 'website/header-footer';
@import 'website/homepage';
@import 'website/products-list';
@import 'website/products-detail';
@import 'website/references';
@import 'website/conseils';
@import 'website/contact';




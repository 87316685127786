/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;

  &:focus {
    outline: none;
  }

  .flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100% !important;

    .flickity-slider {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  &.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    user-select: none;

    .flickity-viewport {
      cursor: move;
      cursor: -webkit-grab;
      cursor: grab;

      &.is-pointer-down {
        cursor: -webkit-grabbing;
        cursor: grabbing;
      }
    }
  }
}


/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;

  &:hover {
    background: white;
    cursor: pointer;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 5px #19F;
  }

  &:active {
    opacity: 0.6;
  }

  &:disabled {
    opacity: 0.3;
    cursor: auto;
    pointer-events: none;
  }

  // Arrow
  .flickity-button-icon {
    fill: currentColor;
  }
}



/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  transform: translateY(-50%);
  
  &.previous {
    left: 10px; 
  }

  &.next {
    right: 10px;
  }

  .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }
}



/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
  
  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background: #333;
    border-radius: 50%;
    opacity: 0.25;
    cursor: pointer;
    
    &.is-selected {
      opacity: 1;
    }
  }
}
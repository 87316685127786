// CONTACT
/*
    
*/
.contact {

	form {
		padding:20px;
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
		margin:30px auto 0;
		background-color: white;

		legend {
			display: block;
			text-transform: uppercase;
			font-size: 2rem;
			font-weight: 600;
		}

		input {
			box-shadow: none;
			font-size: @text-fs;
		}

		label {

			&.required {
				font-weight: 600
			}

		}
	}

	.cnil {
		color:#9b9b9b;
	}

	// Screen > 767px
	._media-s-up({
		form {
			padding:40px;

			legend {
				font-size: 3rem;
			}
		}

		.form-item {
			label {
				display: block;
				line-height: 2em;
			}

			input {
				display: block;
				width: 100%;
			}
		}

		.form-action {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;

			button {
				margin-top:15px;
			}
		}
	});


	// Screen > 989px
	._media-m-up({

		form {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			legend {
				flex:0 0 100%;
				margin-bottom:20px;
			}

			
			.left {
				flex:0 0 57.0%;
				max-width:57%;
			}

			.right {
				flex:0 0 40.0%;
				max-width:40%;

			}
		}

		.left .form-item {
			display: flex;
			align-items: center;

			&:before,
			&:after {
				content:'';
				display:none;
			}

			label {
				line-height: 1.1;
				padding: 0 20px 0 0;
				flex: 0 0 180px;	
			}

			input {
				flex:1;
			}
		}

		textarea {
			min-height:246px;
		}
	});

	._media-l-up({

		form {		
			.left {
				flex:0 0 48.0%;
				max-width:48%;
			}

			.right {
				flex:0 0 48.0%;
				max-width:48%;

			}
		}


		.form-action {
			justify-content: space-between;
			
			button {
				margin-top:0px;
			}
		}
	});
}